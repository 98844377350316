export interface Reserve {
    type: number;
    size: number;
    reserved: number;
}

export interface Destination {
    name: string;
}

export interface Route {
    name: string;
    source: Destination;
    destination: Destination;
}

export interface Departure {
    id: string;
    date_time: string;
    date_time_arrival: string;
    reserves: Reserve[];
    properties: {
        isDangerousCargo: boolean;
    };
    route: Route;
    updated_at: string;
    scan_date: string;
    status: string;
}

export interface DeparturesMap {
    [key: string]: Departure[];
}

export function NewDepartureMap(): DeparturesMap {
    return {}
}
