import {Departure, Route} from "../departures/models";


export interface Subscription {
    id: string;
    departure: Departure;
}


export interface Subscriber {
    id: string;
    subscriptions: Subscription[];

//    _strings: DepartureStrings;
//    _flags: DepartureFlags;
}

export function NewSubscriber(): Subscriber {
    return {
        id: "",
        subscriptions: []
    }
}