import Config from "../../config";
import {Departure, Reserve} from "./models";


export const STATUS_VALID = 'valid';
export const STATUS_STALE = 'stale';
export const STATUS_CANCELLED = 'cancelled';


export interface DeparturePresentation {
    id: string;
    scan_date: string;
    isDeparted: boolean; // Departure has expired, should no longer be visible

    routeName: string;
    routeSourceName: string;
    routeDestinationName: string;
    isDangerousCargo: () => boolean;

    status: string;

    time: string;
    arrivalTime: string;
    month: string;
    day: number;
    weekday: string;
    passengerPercentageProgress: number;
    localPercentageProgress: number;
    passengersDisplayText: string;
    localsDisplayText: string;
    vehiclesDisplayText: string;
    passengersTooltip: string;
    localsTooltip: string;
    vehiclesTooltip: string;
    dangerousCargoTooltip: string;
    progressVariant: string;
    lastUpdateDifferenceCount: number;
    lastUpdateDifferenceUnit: string;

    GetPassengerReserve: () => Reserve
    GetLocalReserve: () => Reserve
    GetVehicleReserve: () => Reserve
}

export interface DepartureDisplayMap {
    [key: string]: DeparturePresentation[];
}

const RESERVE_PASSENGER = 1;
const RESERVE_LOCAL = 2;
const RESERVE_VEHICLE = 3;

class DeparturePresentationMapper {
    private config: Config;

    constructor(config: Config) {
        this.config = config;
    }

    GetView(d: Departure): DeparturePresentation {
        const departureTime = new Date(d.date_time);
        const arrivalTime = new Date(d.date_time_arrival);

        return {
            id: d.id,
            scan_date: d.scan_date,

            routeName: d.route.name,
            routeSourceName: d.route.source.name,
            routeDestinationName: d.route.destination.name,

            isDangerousCargo: function () { return d.properties.isDangerousCargo },

            isDeparted: departureTime < this.config.getCurrentDate(),

            status: d.status,

            time: departureTime.toLocaleString('et-EE', {hour: '2-digit', minute: '2-digit'}),
            arrivalTime: arrivalTime.toLocaleString('et-EE', {hour: '2-digit', minute: '2-digit'}),
            month: 'months.short.' + departureTime.getMonth(),
            day: departureTime.getDate(),
            weekday:  'week.short.' + departureTime.getDay(),
            passengerPercentageProgress: this.calculatePassengerPercentage(d, RESERVE_PASSENGER),
            localPercentageProgress: this.calculatePassengerPercentage(d, RESERVE_LOCAL),
            passengersDisplayText: this.getPassengerDisplayText(d, RESERVE_PASSENGER),
            localsDisplayText: this.getPassengerDisplayText(d, RESERVE_LOCAL),
            vehiclesDisplayText: this.getPassengerDisplayText(d, RESERVE_VEHICLE),
            passengersTooltip: 'tooltips.passenger',
            localsTooltip: 'tooltips.local',
            vehiclesTooltip: 'tooltips.vehicle',
            dangerousCargoTooltip: "tooltips.dangerous",
            progressVariant: this.getProgressVariant(d),
            lastUpdateDifferenceCount: this.calculateUpdateDifference(d),
            lastUpdateDifferenceUnit: "minute",
            GetPassengerReserve: function () { return d.reserves[RESERVE_PASSENGER]; },
            GetLocalReserve: function () { return d.reserves[RESERVE_LOCAL]; },
            GetVehicleReserve: function () { return d.reserves[RESERVE_VEHICLE]; }
        }
    }

   GetSimplifiedView(d: Departure): DeparturePresentation {
        const departureTime = new Date(d.date_time);
        const arrivalTime = new Date(d.date_time_arrival);

        return {
            id: d.id,
            scan_date: d.scan_date,

            routeName: d.route.name,
            routeSourceName: d.route.source.name,
            routeDestinationName: d.route.destination.name,

            isDangerousCargo: function () { return d.properties.isDangerousCargo },

            isDeparted: departureTime < this.config.getCurrentDate(),

            status: d.status,

            time: departureTime.toLocaleString('et-EE', {hour: '2-digit', minute: '2-digit'}),
            arrivalTime: arrivalTime.toLocaleString('et-EE', {hour: '2-digit', minute: '2-digit'}),
            month: 'months.short.' + departureTime.getMonth(),
            day: departureTime.getDate(),
            weekday:  'week.short.' + departureTime.getDay(),
            passengerPercentageProgress: 0,
            localPercentageProgress: 0,
            passengersDisplayText: "",
            localsDisplayText: "",
            vehiclesDisplayText: "",
            passengersTooltip: 'tooltips.passenger',
            localsTooltip: 'tooltips.local',
            vehiclesTooltip: 'tooltips.vehicle',
            dangerousCargoTooltip: "tooltips.dangerous",
            progressVariant: "",
            lastUpdateDifferenceCount: 0,
            lastUpdateDifferenceUnit: "minute",
            GetPassengerReserve: function () { return d.reserves[RESERVE_PASSENGER]; },
            GetLocalReserve: function () { return d.reserves[RESERVE_LOCAL]; },
            GetVehicleReserve: function () { return d.reserves[RESERVE_VEHICLE]; }
        }
    }

    getProgressVariant(d: Departure): string {
        const percentage = this.calculatePassengerPercentage(d, RESERVE_PASSENGER) +
            this.calculatePassengerPercentage(d, RESERVE_LOCAL);

        if (percentage < 80) {
            return "success";
        } else if (percentage < 99) {
            return "warning";
        } else {
            return "danger";
        }
    }

    calculatePassengerPercentage(d: Departure, type: number): number {
        const reserve = this.getReserveByType(d, type)
        if (reserve != null) {
            return 100 * reserve.reserved / this.calculateReserveMax(d);
        }

        return 0;
    }

    getReserveByType(d: Departure, type: number): Reserve | null {
        for (const r of d.reserves) {
            if (r.type === type) {
                return r;
            }
        }

        return null;
    }

    calculateReserveMax(d: Departure): number {
        let max = 0;
        for (const r of d.reserves) {
            if (r.type === 1 || r.type === 2) {
                max += r.size
            }
        }

        return max;
    }

    calculateUpdateDifference(d: Departure): number {
        const updateTime = new Date(d.updated_at)
        updateTime.setHours(updateTime.getHours() - 3)


        const diff = new Date().getTime()*1000 - updateTime.getTime()*1000;

        return (diff / 60);
    }


    getPassengerDisplayText(d: Departure, type: number): string {
        const reserve = this.getReserveByType(d, type)
        if (reserve != null) {
            return reserve.reserved + ' / ' + reserve.size;
        }

        return '';
    }
}

export default DeparturePresentationMapper;
