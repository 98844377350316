import React, {Fragment, RefObject, useRef, useState} from 'react';
import DayCard from "./schedule/day-card";
import DeparturesRepository from "../repository/departures/departures";
import ScrollSpy from "react-ui-scrollspy";
import Config from "../config";
import {DeparturesMap} from "../repository/departures/models";
import {DateEventMap} from "../repository/events/models";
import {CSSTransition} from "react-transition-group";
import EventCard from "./schedule/event-card";

interface ScheduleProps {
    departures: DeparturesMap;
    dateEvents: DateEventMap;
    mobileNavRef: RefObject<HTMLDivElement>
    config: Config
    show: boolean
}

interface MonthDepartures {
    [ key: string ]: DeparturesMap
}

function Schedule (props: ScheduleProps) {
    const dr = new DeparturesRepository(props.config);

    // Group departures by month for scrollspy container
    let months:MonthDepartures = {};
    let otherMonths:MonthDepartures = {};
    let monthsCount = 0;
    for (const dateStr of Object.keys(props.departures)) {
        const d = new Date(dateStr)

        const monthName = dr.getMonthShort(d.getMonth());

        // Init month stores
        if (monthsCount <= 1) {
            if (!months[monthName]) {
                monthsCount += 1;
                // If still under 1, crete store
                if (monthsCount <= 1) {
                    months[monthName] = {};
                }
            }
        }
        // Should init new month ?
        if (monthsCount > 1) {
            if (!otherMonths[monthName]) {
                otherMonths[monthName] = {};
            }
        }

        // First month
        if (monthsCount <= 1) {
            months[monthName][dateStr] = props.departures[dateStr];
        // Other months
        } else  {
            otherMonths[monthName][dateStr] = props.departures[dateStr];
        }
    }

    const scheduleRef = useRef(null);
    //const fullScheduleRef = useRef(null);

    // Delay showing full departures list
    const [showFullDepartures, setShowFullDepartures] = useState(false)
    React.useEffect(() => {
        const timeout = setTimeout(() => {
            setShowFullDepartures(true)
        }, 300)

        return () => clearTimeout(timeout)

    }, [showFullDepartures])




    return (

            <CSSTransition
                in={props.show}
                nodeRef={scheduleRef}
                timeout={200}
                appear
                classNames={{
                    appear: "animated-hidden",
                    appearDone: "animated-visible",
                }}
                className="py-2 container"
            >

                <section ref={scheduleRef}>
                    <div className="row py-0">
                        <ScrollSpy  navContainerRef={props.mobileNavRef} activeClass="active" >
                            {

                                Object.keys(months).map(monthName =>
                                    <div className="col-12" id={monthName} key={'key-'+monthName}>
                                        {
                                            Object.keys(months[monthName]).map(dateStr =>
                                                <>
                                                    {props.dateEvents.hasOwnProperty(dateStr) &&
                                                        <EventCard key={'event-card-' + dateStr}  event={props.dateEvents[dateStr]} date={dateStr} />
                                                    }
                                                    {props.dateEvents.hasOwnProperty(dateStr + '_2') &&
                                                        <EventCard key={'event-card-' + dateStr + '_2'}  event={props.dateEvents[dateStr+'_2']} date={dateStr} />
                                                    }
                                                    <DayCard key={dateStr} departures={props.departures[dateStr]} config={props.config}/>
                                                </>
                                            )
                                        }
                                    </div>
                                )

                            }

                            {showFullDepartures &&

                                Object.keys(otherMonths).map(monthName =>
                                    <div className="col-12" id={monthName} key={'key-'+monthName}>
                                        {
                                            Object.keys(otherMonths[monthName]).map(dateStr =>
                                                <>
                                                    {props.dateEvents.hasOwnProperty(dateStr) &&
                                                        <EventCard key={'event-card-' + dateStr}  event={props.dateEvents[dateStr]} date={dateStr} />
                                                    }
                                                    {props.dateEvents.hasOwnProperty(dateStr + '_2') &&
                                                        <EventCard key={'event-card-' + dateStr + '_2'}  event={props.dateEvents[dateStr+'_2']} date={dateStr} />
                                                    }
                                                    <DayCard key={dateStr} departures={props.departures[dateStr]} config={props.config}/>
                                                </>

                                            )
                                        }
                                    </div>
                                )

                            }
                        </ScrollSpy>
                    </div>
                </section>
            </CSSTransition>



    );

}

export default Schedule;
