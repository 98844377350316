import React, {useEffect, useState} from 'react';
import Jumbotron from "../components/jumbotron";
import Footer from "../components/footer";
import {NewSubscriber, Subscriber} from "../repository/subscribers/models";
import {useTranslation} from 'react-i18next';
import Loader from "../components/loader";

import ScrollSpy from "react-ui-scrollspy";
import ApiClient from "../repository/api-client";
import Config from "../config";
import ErrorPage from "./errorpage";
import DeparturePresentationMapper from "../repository/departures/departure-presentation-mapper";
import Subscription from "../components/subscriptions/subscription";

interface Props {
    config: Config;
}

function Subscriptions(props: Props) {



    const [apiClient] = useState<ApiClient>( new ApiClient(props.config.client))

    const [, setSate] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const [subscriber, setSubscriber] = useState<Subscriber>(NewSubscriber())
    const { t } = useTranslation();

    useEffect(() => {
        document.body.className = '';
    }, [])


    // Load initial data
    useEffect(() => {
        console.log("Loading subscriber data");
        apiClient.getSubscriber(
            'f18fd614-b599-4867-a402-1416399690a9',
            (sub) => {
                setLoading(false);
                setSubscriber(sub);
            },
            (reason: any) => {
                console.log(reason);
                setErrorMessage(reason.code);
                setLoading(false);
            }
        )
    }, [apiClient])



    if (errorMessage) {
        return (
            <ErrorPage message={errorMessage}/>
        );
    }


    return (
        <>
            <Jumbotron
                show={true}
                titleKey="site.title"
                subTitleKey="site.subtitle"
                //linkBefore="site.subtitleLinkBefore"
                //linkAfter="site.subtitleLinkAfter"
            />


            <main>

                <section className="container pt-5">

                        {subscriber.subscriptions.map((s, index) =>
                            <div key={'subscription' + index} className="row">

                                <Subscription config={props.config} subscription={s}/>


                            </div>
                        )}

                </section>



            </main>

            <Footer
                link="tos.link"
                linkText="tos.linkText"
                contactText="footer.contactText"
                beforeLinkText="footer.beforeLinkText"
            />

        </>
    );
}

export default Subscriptions;
