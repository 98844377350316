import React, {Component, useEffect} from 'react';
import {Trans, useTranslation} from "react-i18next";

interface Props {
    contactText: string;
    link: string;
    linkText: string;
    beforeLinkText: string;
}


function Footer(props: Props) {
    const { t } = useTranslation();

    return (

        <footer className="py-5 footer-background text-light fs-6">
            <div className="container mt-5 mb-4">
                <div className="row">
                    <div className="col-6 text-end">
                        <span className="text-shadow">
                           {t(props.contactText)}<br/>
                        </span>
                        <a href="mailto:veeteed@meola.eu">veeteed@meola.eu</a>
                    </div>
                    <div className="col-6 col-sm-3 text-start footer-col-border">
                        {t(props.beforeLinkText)} <a target="_blank" href={process.env.PUBLIC_URL + t(props.link)}>{t(props.linkText)}</a>
                    </div>
                </div>

            </div>
        </footer>
    );

}

export default Footer;
