import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';

interface Props {
    message?: string
}

function ErrorPage(props: Props) {
    useEffect(() => {
        document.body.className = 'page-error';
    }, [])


    const { t } = useTranslation();

    return (
        <main className="container-fluid min-vh-100 min-vw-100">
            {/*Mobile*/}
            <div className="d-block d-sm-none text-center pt-5">

                <h1 className="fw-bold text-light text-shadow">Error</h1>

                <p className="mt-2 fw-bold text-light text-shadow">{t('page_error')}</p>
                <p>
                    <a href="https://veeteed.meola.eu">veeteed.meola.eu</a>
                </p>

            </div>

            {/*Desktop*/}
            <div className="d-none d-sm-block text-center pt-5 px-5">
                <h1 className="fw-bold text-light text-shadow">Error</h1>

                <p className="fw-bold text-light text-shadow">{t('page_error')}</p>

                {props.message &&
                    <p><small className="fs-6 text-light text-shadow">{props.message}</small></p>
                }

                <p>
                    <a href="https://veeteed.meola.eu">veeteed.meola.eu</a>
                </p>

            </div>


        </main>
    );
}

export default ErrorPage;
