import Config from "../../config";
import {Departure, DeparturesMap} from "./models";


class DeparturesRepository {
    private config: Config;

    constructor(config: Config) {
        this.config = config;
    }

    getInProgressDeparture(deps: DeparturesMap): Departure | null {
        const firstDate = Object.keys(deps)[0] || null;
        if (!firstDate) {
            return null;
        }

        const currentDate = this.config.getCurrentDate();
        for (const dep of deps[firstDate]) {
            let departure = new Date(dep.date_time);
            let arrival = new Date(dep.date_time_arrival);

            if (departure < currentDate && currentDate < arrival) {
                return dep;
            }

            // Use this for debug, comment for prod
            if (false) {
                dep.date_time = '2024-04-12 17:00:00';
                dep.date_time_arrival = '2024-04-12 19:50:00';
                return dep;
            }
        }

        return null
    }

    getInProgressDepartureProgress(dep: Departure): number {
        let departure = new Date(dep.date_time);
        let arrival = new Date(dep.date_time_arrival);

        const departureTime = departure.getTime() / 1000;
        const arrivalTime = arrival.getTime() / 1000;
        const c = this.config.getCurrentDate().getTime() / 1000 - departureTime;


        return c * 100 / (arrivalTime-departureTime);
    }

    getMonthShort(month: number): string {
        switch (month) {
            case 0: return 'jan';
            case 1: return 'feb';
            case 2: return 'mar';
            case 3: return 'apr';
            case 4: return 'mai';
            case 5: return 'jun';
            case 6: return 'jul';
            case 7: return 'aug';
            case 8: return 'sep';
            case 9: return 'okt';
            case 10: return 'nov';
            case 11: return 'det';
        }

        return '';
    }
}

export default DeparturesRepository;
