import React, {useEffect, useRef} from 'react';
import {useTranslation} from "react-i18next";
import {CSSTransition} from "react-transition-group";

interface Props {
    titleKey: string;
    subTitleKey?: string;
    linkBefore?: string;
    linkAfter?: string;
    show: boolean
}


function parallax_height() {
    var scroll_top = document.body.getBoundingClientRect().top; // $(this).scrollTop();
    const header = document.getElementById('header');
    const headerText = document.getElementById('header');
    if (header && headerText) {
        header.style.backgroundPositionY = 70  + (scroll_top / 20) + '%';
    }
}

function Jumbotron(props: Props) {
    const { t } = useTranslation();

    useEffect(() => {
        window.removeEventListener('scroll', parallax_height);
        window.removeEventListener("resize", parallax_height);
        window.addEventListener('scroll', parallax_height);
        window.addEventListener("resize", parallax_height);
    }, [])

    const jumbotronRef = useRef(null);

    return (
        <CSSTransition
            in={props.show}
            nodeRef={jumbotronRef}
            timeout={200}
            appear
            classNames={{
                appear: "animated-hidden",
                appearDone: "animated-visible",
            }}
            className="py-5 text-center container-fluid header-image"
        >

            <header id="header" ref={jumbotronRef} >
                <div className="row py-lg-4 veeteed-header">
                    <div className="col-lg-6 col-md-8 mx-auto" id="header-text">
                        <img src={process.env.PUBLIC_URL + '/logo-400.png'} className="w-20 py-4" alt="Logo" />

                            <h1 className="text-light fs-2 text-shadow">{t(props.titleKey)}</h1>
                            <p className=" text-light fs-6 text-shadow">
                                {props.subTitleKey && t(props.subTitleKey)}<br />
                                {props.linkBefore && t(props.linkBefore)}

                                {props.linkBefore &&
                                    <a href="https://www.tuuleliinid.ee/" target="_blank">Tuule
                                    Liinid OÜ</a>
                                } &nbsp;
                                {props.linkAfter && t(props.linkAfter)}
                            </p>

                    </div>
                </div>
            </header>

        </CSSTransition>
    );

}

export default Jumbotron;