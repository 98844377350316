import {changeLanguage} from "i18next";
import React, {RefObject, useEffect, useRef} from "react";
import DeparturesRepository from "../../repository/departures/departures";
import {useTranslation} from "react-i18next";
import Config from "../../config";
import {DeparturesMap} from "../../repository/departures/models";
import {CSSTransition} from "react-transition-group";


// TODO: Move to a component
interface Anchors {
    [ key: string ]: string
}


interface Props {
    departures: DeparturesMap;
    mobileNavRef: RefObject<HTMLDivElement>;
    config: Config;
    show: boolean
}

function Navigation(props: Props) {


    const departuresRepo = new DeparturesRepository(props.config);

    const { t } = useTranslation();

    const navigationRef = useRef(null);

    let anchors:Anchors = {};
    let currentMonth = 0;

    for (const dateStr of Object.keys(props.departures)) {
        const d = new Date(dateStr)
        if (currentMonth !== d.getMonth()) {
            currentMonth = d.getMonth()
            anchors[departuresRepo.getMonthShort(currentMonth)] = t('months.short.' + currentMonth);
        }
    }

    useEffect(() => {
        window.addEventListener('scroll', () => {
            const navbar = document.getElementById('navbar_month_mobile');
            let threshold = 300;
            const header = document.getElementById('header');
            if (header) {
                threshold = header.getBoundingClientRect().height + 50;
            }

            if (navbar && window.scrollY > threshold) {
                navbar.classList.add('scroll');

                // add padding top to show content behind navbar
                // navbar_height = document.querySelector('.navbar').offsetHeight;
                // document.body.style.paddingTop = navbar_height + 'px';
            } else if (navbar) {
                navbar.classList.remove('scroll');

                // remove padding top from body
                //document.body.style.paddingTop = '0';
            }
        });
        // window.removeEventListener("resize", parallax_height);
        // window.addEventListener('scroll', parallax_height);
        // window.addEventListener("resize", parallax_height);
    }, [])



    return (
        <>

            <CSSTransition
                in={props.show}
                nodeRef={navigationRef}
                timeout={200}
                appear
                classNames={{
                    appear: "animated-hidden",
                    appearDone: "animated-visible",
                }}
                className="nav pt-2 pb-3 "
            >

                <nav id="navbar_top" ref={navigationRef}>
                    <div className="container">
                        <div className="row">
                            <div className="col-8 col-sm-9 text-uppercase">
                                <div className="btn-group btn-group-sm shadow" role="group">
                                    {
                                        Object.keys(anchors).map(anchor =>
                                            <a key={'anc-' + anchor} className="btn btn-dark" href={'#' + anchor}>{ anchors[anchor] }</a>
                                        )
                                    }
                                </div>

                            </div>
                            <div className="col-4 col-sm-3 text-end">

                                <div className="btn-group btn-group-sm" role="group">
                                    <button type="button" className="btn btn-dark" onClick={()=>changeLanguage("et")}>
                                                <span className="d-inline d-sm-none">
                                                    ET
                                                </span>
                                        <span className="d-none d-sm-inline">
                                                    Eesti
                                                </span>
                                    </button>
                                    <button type="button" className="btn btn-dark" onClick={()=>changeLanguage("en")}>
                                                <span className="d-inline d-sm-none">
                                                    EN
                                                </span>
                                        <span className="d-none d-sm-inline">
                                                    English
                                                </span>

                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </nav>
            </CSSTransition>

            <nav className="nav month-navbar-mobile " id="navbar_month_mobile">
                <div className="text-uppercase">
                    <div className="btn-group-vertical btn-group-sm shadow " role="group" ref={props.mobileNavRef}>

                        {
                            Object.keys(anchors).map(anchor =>
                                <a data-to-scrollspy-id={anchor} key={'anc-' + anchor} className="btn btn-dark" href={'#' + anchor}>{ anchors[anchor] }</a>
                            )
                        }

                    </div>
                </div>
            </nav>
        </>

    );
}

export default Navigation;