import Config from "../../config";
import DeparturePresentationMapper from "../../repository/departures/departure-presentation-mapper";
import {Subscription as SubscriptionModel} from "../../repository/subscribers/models";
import React from "react";
import {useTranslation} from "react-i18next";


interface Props {
    config: Config;
    subscription: SubscriptionModel;
}

function Subscription(props: Props) {

    const view = new DeparturePresentationMapper(props.config)
    const departure = view.GetSimplifiedView(props.subscription.departure)
    const { t } = useTranslation();

    return (
        <div className="card border-0">
            <div className="card-body">
                <div className="row">
                    <div className="d-none d-lg-block col-3">

                    </div>
                    <div className="d-none d-sm-block col-sm-2 col-lg-1">
                        {departure.day} {t(departure.month)}
                    </div>
                    <div className="d-none d-sm-block col-sm-2 col-lg-1 text-start">
                        {departure.time}
                    </div>
                    <div className="col-7 col-sm-5 col-lg-3">
                        <h4>{departure.routeName}</h4>
                        <div className="d-sm-none">
                            {departure.day} {t(departure.month)}  {departure.time}

                        </div>
                    </div>
                    <div className="col-3 col-sm-3 text-end">
                        <button className="btn btn-danger">Unsubscribe</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Subscription;