import React, {useEffect} from 'react';
import {useTranslation} from 'react-i18next';


function NotFound() {
    useEffect(() => {
        document.body.className = 'page-404';
    }, [])


    const { t } = useTranslation();

    return (
        <main className="container-fluid min-vh-100 min-vw-100">
            {/*Mobile*/}
            <div className="d-block d-sm-none text-center pt-5">

                <h1 className="fw-bold text-light text-shadow">404</h1>

                <p className="mt-2 fw-bold text-light text-shadow">{t('page_not_found')}</p>
                <p>
                    <a href="/">{t('go_to_homepage')}</a>
                </p>

            </div>

            {/*Desktop*/}
            <div className="d-none d-sm-block text-start pt-5 px-5">
                <h1 className="fw-bold text-light text-shadow">404</h1>

                <p className="fw-bold text-light text-shadow">{t('page_not_found')}</p>
                <p>
                    <a href="/">{t('go_to_homepage')}</a>
                </p>

            </div>


        </main>
    );
}

export default NotFound;
