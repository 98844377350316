import React, {useEffect} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Schedule from "../pages/schedule";
import Subscriptions from "../pages/subscriptions";
import NotFound from "../pages/notfound";
import TermsOfServiceEstonia from "../pages/terms-of-service-ee";
import TermsOfServiceEnglish from "../pages/terms-of-service-en";
import Config from "../config";
import ErrorPage from "../pages/errorpage";
import SleepPage from "../pages/sleeppage";


interface Props {
    config: Config;
}

function PageRoutes(props: Props)  {

    return (
        <Router basename="/">

                <Routes>
                    {/* NOTE: Uncomment this to enable schedule */}
                    <Route path="/" element={<Schedule config={props.config} />} />

                    {/* NOTE: Comment this to disable sleep mode */}
                    {/*<Route path="/" element={<SleepPage />} />*/}


                    {/* Uncomment to enable, sleep mode time disabled*/}
                    <Route path="/subscriptions" element={<Subscriptions config={props.config} />} />

                    <Route path="/et/privaatsuspoliitika" element={<TermsOfServiceEstonia />} />
                    <Route path="/en/privacy-policy" element={<TermsOfServiceEnglish />} />

                    <Route path="/404" element={<NotFound />} />
                    <Route path="/500" element={<ErrorPage />} />
                    <Route path="/sleep" element={<SleepPage />} />


                    <Route path="*" element={<NotFound />} />

                </Routes>



        </Router>
    );
}

export default PageRoutes;
