import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources = {
    et: {
        translation: {
            "months": {
              "short": {
                  "0": "jan",
                  "1": "veb",
                  "2": "mar",
                  "3": "apr",
                  "4": "mai",
                  "5": "jun",
                  "6": "jul",
                  "7": "aug",
                  "8": "sep",
                  "9": "okt",
                  "10": "nov",
                  "11": "det"
              }
            },
            "week": {
                "short": {
                    "0": "P",
                    "1": "E",
                    "2": "T",
                    "3": "K",
                    "4": "N",
                    "5": "R",
                    "6": "L",
                },
            },
            "site": {
                "title": "Runö laevagraafik",
                "subtitle": "Agregeeritud andmed Ruhnu liinide kohta.",
                "subtitleLinkBefore": "Sait jälgib andmeid ",
                "subtitleLinkAfter": "veebilehel umbes tunnise sagedusega."
            },
            "subscription": {
                "title": "Runö laevagraafik",
                "subtitle": "Teadete tellimused",
            },
            "tos": {
                "title": "veeteed.meola.eu",
                "link": "/et/privaatsuspoliitika",
                "linkText": "Kasutustingimused ja privaatsuspoliitika",
                "cookie": "See sait kasutab küpsiseid, et jälgida kasutamise statistikat ja täiustada elamust.",
                "cookie_button": "Nõus!",
            },
            "tooltips": {
                "dangerous": "Ohtlike veoste reis - kütus, raketid ja pommid",
                "passenger": "Reisijad: {{reserve.reserved}} reserveeritud {{reserve.size}}-st",
                "local": "Kohalikud: {{reserve.reserved}} reserveeritud {{reserve.size}}-st",
                "vehicle": "Jalgrattad: {{reserve.reserved}} reserveeritud {{reserve.size}}-st",
            },
            "lastUpdatedMinutes": "Viimati uuendatud {{count}} minutit tagasi",
            "lastUpdatedHours": "Viimati uuendatud {{count}} tundi tagasi",
            "footer": {
                "contactText": "Leidsid vea või soovid küsida?",
                "beforeLinkText": "Loe"
            },
            "page_not_found": "... sia oo nüid kindlast eksün..ning ...",
            "go_to_homepage": "Mine avalehele",
            "page_error": "Tundub, et tegemist on veaga. Proovige hiljem uuesti.",

            "status": {
                "stale": "Pole mõnda aega saanud uuendusi selle reisi kohta - võimalik et tühistatud",
                "cancelled": "Väljumine on tühistatud",
            },

            "event_see_more": "Vaata lähemalt",
        }
    },
    en: {
        translation: {
            "months": {
                "short": {
                    "0": "jan",
                    "1": "feb",
                    "2": "mar",
                    "3": "apr",
                    "4": "may",
                    "5": "jun",
                    "6": "jul",
                    "7": "aug",
                    "8": "sep",
                    "9": "oct",
                    "10": "nov",
                    "11": "dec"
                }
            },
            "week": {
                "short": {
                    "0": "Sun",
                    "1": "Mon",
                    "2": "Tue",
                    "3": "Wed",
                    "4": "Thu",
                    "5": "Fri",
                    "6": "Sat",
                },
            },
            "site": {
                "title": "Ruhnu ferry schedule",
                "subtitle": "Aggregated data about ferry line.",
                "subtitleLinkBefore": "Site polls ferry information from ",
                "subtitleLinkAfter": "website around every hour."
            },
            "subscription": {
                "title": "Ruhnu ferry schedule",
                "subtitle": "Notification subscriptions",
            },
            "tos": {
                "title": "veeteed.meola.eu",
                "link": "/en/privacy-policy",
                "linkText": "Terms of service and privacy policy",
                "cookie": "This site uses cookies to track website usage and to enhance user experience.",
                "cookie_button": "Got it!",
            },
            "tooltips": {
                "dangerous": "Trip with dangerous cargo - fuel, rockets and bombs",
                "passenger": "Passenger: {{reserve.reserved}} reserved out of {{reserve.size}}",
                "local": "Locals: {{reserve.reserved}} reserved out of {{reserve.size}}",
                "vehicle": "Bicycles: {{reserve.reserved}} reserved out of {{reserve.size}}"

            },
            "lastUpdatedMinutes": "Last updated {{count}} minutes ago",
            "lastUpdatedHours": "Last updated {{count}} hours ago",
            "footer": {
                "contactText": "Found a bug or have questions?",
                "beforeLinkText": "Read our"
            },
            "page_not_found": "you seem to be lost...",
            "go_to_homepage": "Go to homepage",
            "page_error": "We seem to have trouble showing this page. Try again later.",

            "status": {
                "stale": "Have not seen this departure for a while - possibly cancelled",
                "cancelled": "This departure has been cancelled",
            },

            "event_see_more": "More",
        }
    }
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        lng: localStorage.getItem('i18nextLng') || "et",
        fallbackLng: "en",
        debug: true,
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
//
// class TranslationProvider {
//     DEFAULT_LANG = 'et'
//     catalog = {}
//     lang = this.DEFAULT_LANG;
//
//     init() {
//         const {translations} = catalog;
//         this.catalog = translations;
//         this.setLanguage(this.getLanguage());
//     }
//
//     getLanguage(): string {
//         const lang = localStorage.getItem("lang");
//         if (lang === null) {
//             return this.DEFAULT_LANG
//         }
//
//         return lang
//     }
//
//     setLanguage(lang: string): void {
//         this.lang = lang
//         localStorage.setItem("lang", lang);
//     }
//
//     mapKey(key: string, value: any): string {
//         const v = this.catalog["et"]["months_short"][value];
//
//         return key;
//     }
// }
//
// export default TranslationProvider;
