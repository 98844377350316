import React from 'react';
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
//import {useTranslation} from "react-i18next";
import {DateEvent} from "../../repository/events/models";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

library.add(fas);

interface EventCardProps {
    event: DateEvent
    date: string
}

function EventCard (props: EventCardProps) {
    //const { t } = useTranslation();

    //console.log('event', props.event)
    /*const view = new DeparturePresentationMapper(props.config)

    const defaultDepartureView = view.GetView(props.departures[0]);


    // TODO: Move to view?
    const updatedTime = new Date(defaultDepartureView.scan_date);
    const currentTime = new Date();
    let updateValueMinutes = Math.round((currentTime.getTime() - updatedTime.getTime()) / 1000 / 60);
    let lastUpdated = t('lastUpdatedMinutes', {count: updateValueMinutes});
    if (updateValueMinutes > 70) {
        lastUpdated = t('lastUpdatedHours', {count: Math.round(updateValueMinutes / 60)})
    }

    let somethingToDisplay = true;
    const departures:DeparturePresentation[] = []
    for (const d of props.departures) {
        const departure = view.GetView(d)
        departures.push(departure)
        somethingToDisplay = !departure.isDeparted;
    }

    if (!somethingToDisplay) {
        return (
            <></>
        );
    }*/

    return (
        <>
        <div className="card event-card rounded-0 border-0 shadow mb-4 position-relative h-100"  style={{"backgroundImage" : "url('" + props.event.imageUrl + "')"}}>
            {/*<div className="card-header d-block d-md-none border-0 text-uppercase text-muted py-3 fs-7 bg-white">
                ... mobile
            </div>*/}
            <div className="card-body row p-3 p-md-0 h-100 flex-fill">

                <div className="row g-0 flex-fill">
                    <div className="col-12  text-center d-md-block flex-fill align-content-center " style={{"minHeight" : "80px"}}>
                        <span className="event-title text-shadow">{props.event.title}</span><br/>
                        <span className="event-desc text-shadow">{props.event.description}</span><br/>
                        <br/>
                        {props.event.link &&
                            <a className="event-link text-shadow" href={props.event.link} target="_blank" rel="noreferrer">
                                {props.event.linkText} &nbsp;
                                <FontAwesomeIcon icon={["fas", "angle-double-right"]} size={"sm"}  /></a>
                        }

                    </div>
                </div>
            </div>
{/*            <div className="card-footer border-0 text-muted fs-6 fst-italic bg-white p-3 p-md-0">
                <div className="row g-0">
                    <div className="col-1 col-md-1 col-lg-1 d-none d-md-block">

                    </div>
                    <div className="col-1 col-md-2 col-lg-1 d-none d-md-block">

                    </div>
                    <div className="col-9 py-2">
                        <small>something small</small>
                    </div>
                </div>
            </div>*/}
        </div>
        </>
    );
}

export default EventCard;
