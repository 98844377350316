import axios from "axios";
import {Events} from "../../config";
import {DateEventMap} from "./models";

class EventsClient {

    private config: Events;

    /**
     * @param config
     */
    constructor(config: Events) {
        this.config = config;
    }

    getEvents(
        done: (data: DateEventMap) => void,
        fail: (reason: any) => void,
    ): void {

        axios.get<DateEventMap>(this.config.eventsUrl)
            .then(response => done(response.data))
            .catch(reason => fail(reason))
    }

}

export default EventsClient;
