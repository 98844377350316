function Loader() {
    return (

    <div className="text-center">
        <div className="ship-loader">
            <img src={process.env.PUBLIC_URL + '/runo200.png'} alt="" />
                <div className="loader-wave">

                </div>
        </div>
        <span className="visually-hidden">Loading...</span>
    </div>

    );
}

export default Loader;