import React, {useEffect} from 'react';
import Jumbotron from "../components/jumbotron";
import {changeLanguage} from "i18next";

function TermsOfServiceEstonia() {

    useEffect(() => {
        changeLanguage("et")
    }, []);

    useEffect(() => {
        document.body.className = '';
    }, [])

    return (
        <>
            <Jumbotron
                show={true}
                titleKey="tos.title"
                //subTitleKey="site.subtitle"
                //linkBefore="site.subtitleLinkBefore"
                //linkAfter="site.subtitleLinkAfter"
            />


            <main>

                <article className="mt-4 pt-5 pb-5  container text-dark lh-sm">

                <h1>Kasutustingimused ja privaatsuspoliitika</h1>

                <p className="alert-warning"><b>*</b> Hetkel ei ole tehnilistel põhjustel võimalik teavituste tellimine. </p>
                <br/>

                <p>Kasutades veeteed.meola.eu veebilehte, kinnitate et olete lugenud kasutajatingimusi ning nõustute nendega.</p>
                <h4>Üldine</h4>
                <p>Veeteed.meola.eu on veebirakendus, mis agregeerib perioodiliselt Ruhnu liinide andmeid <a href="https://www.tuuleliinid.ee">Tuule Liinid OÜ</a>
                    veebilehelt ja kuvab neid mugava ülevaatena.
                    Kuna andmete kontrollimisel on võimalik teha ka oletusi kohtade vabanemisest, siis on võimalus kasutajatel registreerida end e-maili teel
                    ka saama teavitusi kui neile huvipakkuval reisil vabanevad kohad.</p>
                <p>Veeteed.meola.eu ei oma seotust Tuule Liinid OÜ tegevusega ning ei vastuta nende veebisaidil avaldatud informatsiooni õigsuse eest.</p>

                <h4 className="mt-4" >Kasutaja isikuandmete säilitamine ja töötlemine</h4>
                <ul>
                    <li>
                        <p>Selleks, et veeteed.meola.eu saaks saata teavitusi kohtade vabanemisest, on tarvis kasutajal sisestada oma meiliaadress.
                            Lisaks talletatakse registreerimise hetkel ka veebilehel kuvatud keel, et hiljem teateid saates saaks seda kasutada.</p>
                    </li>
                    <li>
                        <p>Kasutajal on alati võimalik teavitustest keelduda, kasutades meilile saadetud juhiseid.</p>
                    </li>
                    <li>
                        <p>Meiliaadress säilitatakse kuni see on eesmärgi täitmiseks vajalik. Kui kasutaja loobub kõikidest teavitustest
                            või jälgitavad reisid aeguvad, siis kasutaja meiliaadress depersonaliseeritakse 24 tunni jooksul - andmebaasis asendatakse e-posti aadressid räsiga.
                            Tellitud teavituste kirjed säilitatakse edaspidi vaid statistilisel eesmärgil.</p>
                    </li>
                    <li>
                        <p>Statistilisi andmeid kuvatakse veeteed.meola.eu veebilehel.</p>
                    </li>
                    <li>
                        <p>Isiksustatud andmeid ei jagata kolmandate osapooltega.</p>
                    </li>
                    <li>
                        <p>Teavituste meiliaadressid hoitakse andmebaasis krüpteeritud kujul.</p>
                    </li>
                </ul>
                <h4 className="mt-4" >Kogutavad andmed</h4>
                <ul>
                    <li>Veeteed.meola.eu kogub järgnevaid andmeid oma kasutajatelt
                        <ul>
                            <li>Veebilehe külastuste statistika, mida kogutakse Google Analytics abil - <a href="https://policies.google.com/technologies/partner-sites">How Google uses information from sites or apps that use our services</a></li>
                            <li>Teavituse tellija e-posti aadress ja keelevalik.</li>
                            <li>Veebileht kasutab küpsiseid külastuste statistika jaoks ja kasutaja nõusolekut teenuse tingimustega.</li>
                        </ul></li>
                </ul>
                <h4 className="mt-4" >Pretensioonid</h4>
                <p>Juhul kui teil on pretensioone või küsimusi, siis palun võtke ühendust veeteed@meola.eu.</p>


                </article>

            </main>


        </>
    );
}

export default TermsOfServiceEstonia;
