/**
 * Configs from .env file
 * Generated by env.sh
 */

interface Config {
    env: {
        START_DATE: string;
    },
    client: Client;
    events: Events;

    getStartDate: () => string // Probably worth making it as Date. TODO: See if any usages
    getCurrentDate: () => Date
}

export interface Client {
    baseUrl: string;
    departureGroupId: string;
    getStartDate: () => string // Probably worth making it as Date
}

export interface Events {
    eventsUrl: string;
}

export function NewConfig(): Config {
    return {
        env: {
            START_DATE: window._env_.START_DATE,
        },
        client: {
            baseUrl: window._env_.VEETEED_API_URL,
            departureGroupId: "34686154-1b01-4d3f-a230-ae4ee267ad35",
            getStartDate: getStartDateFunc,
        },
        events: {
            eventsUrl: window._env_.EVENTS_API_URL,
        },

        /**
         * Get Start date of the departures query
         */
        getStartDate: getStartDateFunc,

        /**
         * Get Current date
         */
        getCurrentDate: () => {
            //return new Date('2022-05-20 09:50:00');
            return new Date();
        }
    }
}

function getStartDateFunc(): string {
    // Set from .env
    if (window._env_.START_DATE && window._env_.START_DATE.length > 3) {
        return window._env_.START_DATE
    }

    const yourDate = new Date()

    // Get current date
    return yourDate.toISOString().split('T')[0] + " 00:00:00"; // YYYY-MM-DD'
}


export default Config;
