import React, {useState} from 'react';
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import DeparturePresentationMapper, {
    DeparturePresentation
} from "../../repository/departures/departure-presentation-mapper";
import DepartureView from "./departure-view";
import Config from "../../config";
import {Departure} from "../../repository/departures/models";

library.add(fas);

interface DayCardProps {
    departures: Departure[]
    config: Config
}

function DayCard (props: DayCardProps) {
    const { t } = useTranslation();

    const view = new DeparturePresentationMapper(props.config)

    const defaultDepartureView = view.GetView(props.departures[0]);


    // TODO: Move to view?
    const updatedTime = new Date(defaultDepartureView.scan_date);
    const currentTime = new Date();
    let updateValueMinutes = Math.round((currentTime.getTime() - updatedTime.getTime()) / 1000 / 60);
    let lastUpdated = t('lastUpdatedMinutes', {count: updateValueMinutes});
    if (updateValueMinutes > 70) {
        lastUpdated = t('lastUpdatedHours', {count: Math.round(updateValueMinutes / 60)})
    }

    let somethingToDisplay = true;
    const departures:DeparturePresentation[] = []
    for (const d of props.departures) {
        const departure = view.GetView(d)
        departures.push(departure)
        somethingToDisplay = !departure.isDeparted;
    }

    if (!somethingToDisplay) {
        return (
            <></>
        );
    }

    return (
        <>
        <div className="card day-card rounded-0 border-0 shadow mb-4 position-relative">
            <div className="card-header d-block d-md-none border-0 text-uppercase text-muted py-3 fs-7 bg-white">
                {defaultDepartureView.day} / {t(defaultDepartureView.month)} / {t(defaultDepartureView.weekday)}
            </div>
            <div className="card-body row p-3 p-md-0">
                {
                    departures.map((departure)  =>
                        <DepartureView
                            key={departure.id}
                            departure={departure}
                        />
                    )
                }
            </div>
            <div className="card-footer border-0 text-muted fs-6 fst-italic bg-white p-3 p-md-0">
                <div className="row g-0">
                    <div className="col-1 col-md-1 col-lg-1 d-none d-md-block">

                    </div>
                    <div className="col-1 col-md-2 col-lg-1 d-none d-md-block">

                    </div>
                    <div className="col-9 py-2">
                        <small>{lastUpdated}</small>
                    </div>
                </div>
            </div>
        </div>
        {/* Divider for attaching stuff */}
        <div id={'div-' + defaultDepartureView.day + '-' + defaultDepartureView.month} className="d-none"> </div>
        </>
    );
}

export default DayCard;