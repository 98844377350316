import React, {createRef, useEffect, useState} from 'react';
import Jumbotron from "../components/jumbotron";
import Footer from "../components/footer";
import DepartureSchedule from "../components/schedule";
import DeparturesRepository from "../repository/departures/departures";
import Loader from "../components/loader";
import TravelProgress from "../components/progress";
import ApiClient from "../repository/api-client";
import Config from "../config";
import Navigation from "../components/schedule/navigation";
import {DeparturesMap, NewDepartureMap} from "../repository/departures/models";
import { NewDateEventMap} from "../repository/events/models";
import ErrorPage from "./errorpage";
import EventsClient from "../repository/events/events-client";
import {DateEventMap} from "../repository/events/models";


interface Props {
    config: Config;
}

function Schedule(props: Props) {

    const [apiClient] = useState<ApiClient>( new ApiClient(props.config.client))
    const [eventsClient] = useState<EventsClient>( new EventsClient(props.config.events))

    const mobileNavRef = createRef<HTMLDivElement>();
    const departuresRepo = new DeparturesRepository(props.config);

    const [departures, setDepartures] = useState<DeparturesMap>(NewDepartureMap())
    const [, setSate] = useState(0);
    const [loading, setLoading] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const [dateEvents, setDateEvents] = useState<DateEventMap>(NewDateEventMap())

    useEffect(() => {
        document.body.className = 'body-background';
    }, [])

    // Load initial data
    useEffect(() => {
        console.log("Loading schedule data");
        apiClient.getDepartures(
            (departures) => {
                setLoading(false);
                setDepartures(departures);
            },
            (reason: any) => {
                console.log(reason);
                setErrorMessage(reason.code);
                setLoading(false);
            }
        )
    }, [apiClient])

    // Load events data
    useEffect(() => {
        console.log("Loading events data");
        eventsClient.getEvents(
            (dateEvents) => {
                setDateEvents(dateEvents);
            },
            (reason: any) => {
                console.log(reason);
            }
        )
    }, [apiClient])


    // Keep polling updates
    useEffect(() => {
        const iID = setInterval(() => {
            setSate(1)
            console.log("Refreshing schedule data");

            apiClient.getDepartures(
                (departures) => setDepartures(departures),
                (reason: any) => {}
            )

        }, 1000 * 60 * 5); // TODO: Set several minutes, from config
        return () => clearInterval(iID);
    }, [apiClient])




    const currentDeparture = departuresRepo.getInProgressDeparture(departures);
    let currentDepartureProgress = 0;
    if (currentDeparture) {
        currentDepartureProgress = departuresRepo.getInProgressDepartureProgress(currentDeparture);
    }

    if (errorMessage) {
        return (
            <ErrorPage message={errorMessage}/>
        );
    }

    // TODO: Create page loader componenet
    if (loading) {
        return (
            <main>
                <section className="pt-4 pb-2  container ">

                    {loading &&
                        <div className="row">
                            <div className="col-12 text-center mt-5 pt-5 text-white">
                                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                <br/><br/><br/><br/><br/><br/><br/><br/><br/>
                                <Loader />
                            </div>
                        </div>
                    }

                </section>

            </main>

        );
    }

    return (
        <>

            <Jumbotron
                show={!loading}
                titleKey="site.title"
                subTitleKey="site.subtitle"
                linkBefore="site.subtitleLinkBefore"
                linkAfter="site.subtitleLinkAfter"
            />


            <main className=" pt-5">

                {currentDeparture &&
                    <TravelProgress departure={currentDeparture} progress={currentDepartureProgress} config={props.config} />
                }

                <Navigation show={!loading} mobileNavRef={mobileNavRef} departures={departures} config={props.config}/>

                <DepartureSchedule show={!loading} departures={departures} mobileNavRef={mobileNavRef} config={props.config} dateEvents={dateEvents}/>

                <div className="pt-5 pb-5"> </div>
            </main>

            <Footer
                link="tos.link"
                linkText="tos.linkText"
                contactText="footer.contactText"
                beforeLinkText="footer.beforeLinkText"
            />

        </>
    );
}

export default Schedule;
