import DeparturePresentationMapper from "../repository/departures/departure-presentation-mapper";
import Config from "../config";
import {Departure} from "../repository/departures/models";

interface Props {
    departure: Departure
    progress: number
    config: Config
}
function TravelProgress(props: Props) {

    const view = new DeparturePresentationMapper(props.config)
    const departure = view.GetView(props.departure)

    let arrivalTime = departure.arrivalTime;
    let departureTime = departure.time;
    let sourceName = departure.routeSourceName;
    let destinationName = departure.routeDestinationName;
    let className = "pt-2 pb-3 pb-1 container";
    let flipped = false;

    if (destinationName !== "Ruhnu") {
        arrivalTime = departure.time;
        departureTime =  departure.arrivalTime;
        destinationName = departure.routeSourceName;
        sourceName = departure.routeDestinationName;
        className += " progress-flip"
        flipped = true;
    }



    let progress = props.progress / 1.6;

    let shipLeft = 5 + progress ;



    if (flipped) {
        shipLeft = 70 - progress;
    }

    return (

        <section className={className}>
            <div className="row row py-0 mb-4">
                <div className="col-lg-12">
                    <div className="card rounded-5 shadow border-0 " style={{backgroundColor: "#0097c6"}}>

                        <div className="card-body">
                            <div className="row text-light text-shadow">
                                <div className="col ps-4">
                                    {sourceName}<br />
                                    {departureTime}
                                </div>
                                <div className="col text-end pe-4">
                                    {destinationName}<br />
                                    {arrivalTime}
                                </div>
                            </div>

                            <div className="row mt-5">
                                <div className="col">


                                    <div className="ocean w-100">
                                        <div className={flipped ? 'cloud-reverse': 'cloud'}>

                                        </div>
                                        <div className="ship" style={{left: shipLeft + "%"}}>
                                            <img src={process.env.PUBLIC_URL + '/runo200.png'}/>
                                        </div>

                                        <div className={flipped ? 'wave-reverse': 'wave'}>

                                        </div>
                                        <div className={flipped ? 'wave2-reverse': 'wave2'}>

                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>

                    </div>

                </div>
            </div>



        </section>

)
}


export default TravelProgress;