import React from 'react';
import {ProgressBar} from "react-bootstrap";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {library} from "@fortawesome/fontawesome-svg-core";
import {fas} from "@fortawesome/free-solid-svg-icons";
import {useTranslation} from "react-i18next";
import {
    DeparturePresentation,
    STATUS_STALE,
    STATUS_CANCELLED,
    STATUS_VALID
} from "../../repository/departures/departure-presentation-mapper";

library.add(fas);

interface Props {
    departure: DeparturePresentation;
}


function DepartureView (props: Props) {
    const { t } = useTranslation();

    if (props.departure.isDeparted) {
        return (<></>)
    }

    // Status classes
    let additionalClass = '';
    let statusText = '';
    if (props.departure.status === STATUS_STALE) {
        additionalClass += 'text-muted';
        statusText = t('status.' + STATUS_STALE);
    } else if (props.departure.status === STATUS_CANCELLED) {
        additionalClass += 'text-decoration-line-through';
        statusText = t('status.' + STATUS_CANCELLED);
    }

    return (
        <div className="departure">
            <div className="row g-0">
                <div className="col-1 side-date text-center d-none d-md-block text-dark  pt-3" style={{"minHeight" : "80px"}}>
                    <span className="fs-3 text-letterpress text-muted">{props.departure.day}</span><br/>
                    <span className="fw-bold text-muted fs-7 text-uppercase">{t(props.departure.month)}</span><br/>
                    <span className="text-muted fs-6">{t(props.departure.weekday)}</span>
                </div>

                <div className="col-2 col-sm-2 col-md-2 col-lg-1 text-center d-none d-md-block fs-5 fw-bold text-time fw-light mt-3">
                    { props.departure.time }
                </div>
                <div className="col-12 col-md-8 col-sm-12 col-lg-9 text-dark mt-3 mb-3">
                    <h5 className={'d-none d-md-block h-route ' + additionalClass} >{ props.departure.routeName }</h5>
                    <h5 className={'d-block d-md-none h-route ' + additionalClass}>{ props.departure.time } - { props.departure.routeName }</h5>

                    <ProgressBar className="progress-sm rounded-0">
                        <ProgressBar variant={props.departure.progressVariant} now={props.departure.passengerPercentageProgress} key={1} />
                        <ProgressBar className="progress-local" variant={props.departure.progressVariant} now={props.departure.localPercentageProgress} key={2} />
                    </ProgressBar>

                    <div className="d-flex flex-row pt-2 text-muted fs-7">

                        <div className="icon-column" title={t(props.departure.passengersTooltip, {reserve: props.departure.GetPassengerReserve()})}>
                            <FontAwesomeIcon icon={["fas", "children"]} size={"lg"}  />
                            <span className="ms-2">{props.departure.passengersDisplayText}</span>
                        </div>

                        <div className="icon-column" title={t(props.departure.localsTooltip, {reserve: props.departure.GetLocalReserve()})}>
                            <i className="icon-local-native"> </i>
                            <span className="ms-2">{props.departure.localsDisplayText}</span>
                        </div>

                        <div className="icon-column" title={t(props.departure.vehiclesTooltip, {reserve: props.departure.GetVehicleReserve()})}>
                            <FontAwesomeIcon icon={["fas", "bicycle"]} size={"lg"} />
                            <span className="ms-2">{props.departure.vehiclesDisplayText}</span>
                        </div>

                        {props.departure.isDangerousCargo() &&
                            <div title={t(props.departure.dangerousCargoTooltip)} className="icon-column px-2 text-warning">
                                <FontAwesomeIcon icon={["fas", "circle-radiation"]} size={"lg"} />
                            </div>
                        }
                    </div>
                    {props.departure.status === STATUS_STALE &&
                        <div className="d-flex flex-row pt-3 text-warning fs-7">⚠️ &nbsp; {statusText}</div>
                    }
                    {props.departure.status === STATUS_CANCELLED &&
                        <div className="d-flex flex-row pt-3 text-danger fs-7">❌ &nbsp; {statusText}</div>
                    }

                </div>
                <div className="col-1 col-md-1 col-sm-1 text-end d-none d-md-block">
                    {/*<button className="btn btn-light">!</button> */}
                </div>
            </div>
            <div className="row">
                <div className="col-lg-12">
                    {/* A stats graph placeholder */}
                </div>
            </div>
        </div>
    );


}

export default DepartureView;
