import axios from "axios";
import {Client} from "../config";
import {DeparturesMap} from "./departures/models";
import {Subscriber} from "./subscribers/models";

class ApiClient {

    private config: Client;

    /**
     * @param config
     */
    constructor(config: Client) {
        this.config = config;
    }

    getDepartures(
        done: (data: DeparturesMap) => void,
        fail: (reason: any) => void,
    ): void {
        const apiPath = '/api/v1/departures/' + this.config.departureGroupId + '?grouping=date&date_end=2024-10-31 00:00:00&date_start=' + this.config.getStartDate();

        axios.get<DeparturesMap>(this.config.baseUrl + apiPath)
            .then(response => done(response.data))
            .catch(reason => fail(reason))
    }

    getSubscriber(
        subscriberId: string,
        done: (data: Subscriber) => void,
        fail: (reason: any) => void,
    ): void {
        const apiPath = '/api/v1/subscribers/' + subscriberId;

        axios.get<Subscriber>(this.config.baseUrl + apiPath)
            .then(response => done(response.data))
            .catch(reason => fail(reason))
    }

}

export default ApiClient;
