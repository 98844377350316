import React, {useEffect} from 'react';
import Jumbotron from "../components/jumbotron";
import {changeLanguage} from "i18next";

function TermsOfServiceEnglish() {

    useEffect(() => {
        changeLanguage("en")
    }, []);

    useEffect(() => {
        document.body.className = '';
    }, [])

    return (
        <>
            <Jumbotron
                show={true}
                titleKey="tos.title"
                //subTitleKey="site.subtitle"
                //linkBefore="site.subtitleLinkBefore"
                //linkAfter="site.subtitleLinkAfter"
            />


            <main>

                <article className="mt-4 pt-5 pb-5  container text-dark lh-sm">

                    <h1>Terms of service and privacy policy</h1>

                    <p className="alert-warning"><b>*</b> Currently subscribing to the notifications is not available.</p>
                    <br/>


                    <p>Using veeteed.meola.eu website and its services you confirm that you have read and agree with the terms and privacy policy.</p>
                    <h4>General</h4>
                    <p>Veeteed.meola.eu is a web app, that periodically aggregates Ruhnu ferry lines departures from <a href="https://www.tuuleliinid.ee">Tuule Liinid OÜ</a> website
                        and displays them in a convenient overview.
                        As checking the data enables also to detect when seats open up, then users have the ability to subscribe to the notifications and
                        receive messages when a departure of their interest has vacant seats.</p>
                    <p>Veeteed.meola.eu is not to blame for the information published by Tuule Liinid OÜ websites and is in no way associated with them.</p>
                    <h4 className="mt-4">Storing and processing of personal data</h4>
                    <ul>
                        <li>
                            <p>To be able to send notifications about seats vacancies veeteed.meola.eu needs to ask users for their e-mail addresses. In addition
                                the current website language is stored to be used later when sending localized notifications.</p>
                        </li>
                        <li>
                            <p>Users have always ability to opt out from notifications by following the instructions sent in e-mail.</p>
                        </li>
                        <li>
                            <p>E-mail addresses are stored until it is necessary to achieve the objective. If user cancels all their notifications or the subscribed departures expire
                                then e-mail is depersonalized in about 24 hours - in database it is replaced with a one way hash.
                                Depersonalized data is kept for statistical purposes.</p>
                        </li>
                        <li>
                            <p>Statistics are used on the veeteed.meola.eu website.</p>
                        </li>
                        <li>
                            <p>Personalized data is not shared to third parties</p>
                        </li>
                        <li>
                            <p>Subscribers e-mails are stored to database in encrypted form.</p>
                        </li>
                    </ul>
                    <h4 className="mt-4">Data we collect</h4>
                    <ul>
                        <li>Veeteed.meola.eu collects the following data from it's users:
                            <ul>
                                <li>Website usage statistics using Google Analytics - <a href="https://policies.google.com/technologies/partner-sites">How Google uses information from sites or apps that use our services</a></li>
                                <li>Subscribers e-mails and language choice.</li>
                                <li>Website uses cookies to track website usage and to remember the user's choice on the terms service agreement.</li>
                            </ul></li>
                    </ul>
                    <h4 className="mt-4">Complaints and questions</h4>
                    <p>If you have complaints or questions then please contact us via veeteed@meola.eu.</p>

                </article>

            </main>


        </>
    );
}

export default TermsOfServiceEnglish;
