export interface DateEvent {
    title: string;
    description: string;
    status: string;
    imageUrl: string
    link: string
    linkText: string
}

export interface DateEventMap {
    [key: string]: DateEvent;
}

export function NewDateEventMap(): DateEventMap {
    return {}
}
