import React from 'react';
import './scss/App.scss';
import PageRoutes from "./app/pageRoutes";
import CookieConsent from "react-cookie-consent";
import {useTranslation} from "react-i18next";
import Config from "./config";

interface Props {
    config: Config;
}

function App(props: Props) {

    const { t } = useTranslation();

    return (
    <>
        <PageRoutes config={props.config}/>

        {/*NOTE: Uncomment to enable cookie consent: Disabled during sleep*/}
        {<CookieConsent
            location="bottom"
            buttonText={t('tos.cookie_button')}
            cookieName="veeteed_meola_eu_consent"
            style={{ background: "#2B373B" }}
            buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
            expires={150}
            acceptOnScroll={false} // Cool feature, but on loading may cause accidental accept since page is short
            acceptOnScrollPercentage={25}
        >
            {t('tos.cookie')} &nbsp;
            <a href={process.env.PUBLIC_URL + t('tos.link')} target="_blank" rel="noreferrer">{t('tos.linkText')}</a>
        </CookieConsent>}
    </>
    );
}

export default App;
